<template>
    <div></div>
</template>

<script>
    export default {
        data() {
            return {}
        },
        async created () {
            // http://localhost:8005/auth/login?email=vireas@mail.ru&activation_key=test&remember_me=1
            let params = this.$route.query;
            await this.$store.dispatch('auth/autoLogin', { params }).then(async (response) => {
                console.log('response', response)
                this.$store.dispatch('default_data/initialize');
                this.$store.dispatch('navigation/initialize');
                this.$store.dispatch('settings/setPortalInfo');
                this.$store.dispatch('settings/setPaymentDetail');
                if (params['is_registration']) {
                    localStorage.setItem('is_registration', true);
                    await this.$metrika.reachGoal('reg-admin');
                    await this.$router.push('/start');
                } else if (params['redirect']) {
                    localStorage.removeItem('is_registration');
                    await this.$router.push({name: params['redirect']});
                } else {
                    localStorage.removeItem('is_registration');
                    await this.$router.push('/');
                }
            }).catch(() => {
                this.$router.push({name: 'Login'});
            })
        }
    }
</script>
